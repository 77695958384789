import Countdown from "../components/Countdown"
import Navbar from "../components/Navbar"


function Home() {
  return (
    <>
      <Navbar />
      <section>
        <h2>Princesa da minha vida</h2>
      </section>
      <section id="countdown-section">
        <p>saiba que estou contando os segundos para te ver</p>
        <Countdown targetDate={new Date("2024-04-18T22:00:00Z")} />
      </section>
    </>
  )
}


export default Home
