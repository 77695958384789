import {
  Box,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"

import { parseISO } from "date-fns"
import { useState } from "react"
import { useLoaderData } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Jumbotron from "./Jumbotron"
import ResponsiveDialog from "./ResponsiveDialog"
import VerticalLinearStepper from "./Stepper"
import { styled } from "@mui/material/styles"



const StyledSimpleBar = styled(SimpleBar)(() => ({
  "& .simplebar-placeholder": {
    "display": "none",
  },
}))

export const Scorecard = () => {

  const response = useLoaderData()
  const [pointLists, setPointLists] = useState(response.data || [])
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [edit, setEdit] = useState(false)

  // oper for the stepper
  const [open, setOpen] = useState(false)


  const props = {
    pointLists,
    setPointLists,
    open,
    setOpen,
    setSelectedPoint,
    selectedPoint,
    edit,
    setEdit,
    setAnchorEl,
    editable: false,
  }


  // TODO: hardcoded for now
  const pointList = pointLists[0]

  if (response.error) {
    return <>
      <section>
        <Jumbotron title={"oops!"} description={"ocorreu um erro ao carregar a lista: " + response.error} />
      </section>
    </>
  }

  if (pointList == null) {
    return <>
      <section>
        <Jumbotron title={"oops!"} description={"lista de pontos não encontrada!"} />
      </section>
    </>
  }

  return (
    <>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          {/* <Stack spacing={1}>
            <Typography variant="h4">
              Extrato
            </Typography>
          </Stack> */}
        </Stack>
        <Card>
          <StyledSimpleBar>
            {/* <Box sx={{ minWidth: 800 }}> */}
            <Box>
              <Table sx={{ maxWidth: "100%" }} >
                <TableHead>
                  <TableRow
                    style={{ height: "auto" }}
                  >
                    {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAll}
                    indeterminate={selectedSome}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onSelectAll?.()
                      } else {
                        onDeselectAll?.()
                      }
                    }}
                  />
                </TableCell> */}
                    {pointList?.points?.length ? (
                      <>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Descrição
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Valor
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Data
                        </TableCell>
                      </>
                    ) : ""}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pointList?.points?.map((point) => {
                    // const isSelected = selected.includes(point.id)

                    const dateAdded = parseISO(point.date).toLocaleDateString("pt-BR")

                    return (
                      <TableRow
                        hover
                        key={point.id}
                        onClick={(event) => {
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setSelectedPoint(selectedPoint ? null : point)
                        }}
                      // selected={isSelected}
                      >
                        {/* <TableCell padding="checkbox">
                      <Checkbox
                      // checked={isSelected}
                      // onChange={(event) => {
                      //   if (event.target.checked) {
                      //     onSelectOne?.(point.id)
                      //   } else {
                      //     onDeselectOne?.(point.id)
                      //   }
                      // }}
                      />
                    </TableCell> */}
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {point.description}
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", color: point.value < 0 ? "red" : "green" }}>
                          {point.value}
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {dateAdded}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </StyledSimpleBar>

          {/* <Popper open={popperOpen}>
            <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
              <MenuList>
                <MenuItem >
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem>
                  <DeleteIcon />
                  Delete
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popper> */}



          <div>
            <ResponsiveDialog {...props}>
              <VerticalLinearStepper />
            </ResponsiveDialog>
          </div>


          {/* <TablePagination
        component="div"
        count={pointList.points?.length || 0}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}
        // page={page}
        rowsPerPage={100}
        rowsPerPageOptions={[10, 50, 100]}
      /> */}
        </Card>


      </Container >
    </>
  )
}


export default Scorecard
