import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from "@mui/material"
import ImageUploader from "./ImageUploader"


export default function AccountProfile({ user }) {

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Avatar
            src={user.profilePicture}
            sx={{
              height: 80,
              mb: 2,
              width: 80
            }}
          />
          {user.name && (
            <Typography
              gutterBottom
              variant="h5"
            >
              {user.name}
            </Typography>
          )}
          <Typography
            color="text.secondary"
            variant="body2"
          >
            {user.username}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <ImageUploader user={user} />
      </CardActions>
    </Card>
  )

}
