import "./App.css"
import Invite from "./sections/Invite"
import Home from "./sections/Home"

import { ThemeProvider } from "@mui/material/styles"
import { createTheme } from "./theme"
import Login from "./sections/Login"

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import ErrorPage from "./components/Error"
import loginAction from "./actions/login"
import { protectedLoader, logoutLoader, todoListLoader, meLoader, pointsLoader, pointsEvaluatedLoader } from "./loaders/loaders"
import { SnackbarProvider } from "notistack"
// import UnderDevelopment from "./components/UnderDevelopment"
import Todo from "./sections/Todo"
import Profile from "./sections/Profile"
import { userUpdateAction } from "./actions/users"
import Points from "./sections/Points"
import { AccessTokenProvider } from "./helpers/contexts"
import Score from "./sections/Score"


const theme = createTheme()
const router = createBrowserRouter([
  {
    id: "root",
    path: "",
    element: <Home />,
    loader: protectedLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/convite",
    element: <Invite />,
    loader: protectedLoader,
  },
  {
    path: "/login",
    element: <Login />,
    action: loginAction,
  },
  {
    path: "/logout",
    loader: logoutLoader,
  },
  {
    path: "/perfil",
    element: <Profile />,
    loader: meLoader,
    action: userUpdateAction,
  },
  {
    path: "/listinha",
    element: <Todo />,
    loader: todoListLoader,
  },
  {
    path: "/pontuar",
    element: <Points />,
    loader: pointsLoader,
  },
  {
    path: "/extrato",
    element: <Score />,
    loader: pointsEvaluatedLoader,
  }
])


function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AccessTokenProvider>
          <RouterProvider router={router} />
        </AccessTokenProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
