import { Avatar, Chip, Container } from "@mui/material"



// function UserInfo() {
//   return (
//     <Container>
//       <Avatar alt="" src={localStorage.getItem("profilePicture")} />
//     </Container>
//   )
// }


// export default UserInfo


import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { useAccessToken } from "../helpers/contexts"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0",
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 400,
  boxShadow: "none",
  marginLeft: "unset!important",
}))


const getChipColors = (points) => ({
  backgroundColor: points < 0 ? "#ff3838" : "#32ff7e",
  color: points < 0 ? "#fff" : "#000",
  "&:hover": {
    backgroundColor: "#FF69B4", // Change this color to your desired hover color
  }
})

export default function UserInfo({ profilePicture, name, points }) {

  const navigate = useNavigate()
  const userData = useAccessToken()


  if (name === undefined || name === "") {
    name = userData?.name
    profilePicture = userData?.profilePicture
  }

  return (
    <Container sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
      <Item
        sx={{
          my: 1,
          mx: "auto",
          p: 2,
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar alt="" src={profilePicture} />
          <Stack spacing={0} direction="column">
            <Typography variant="h6" sx={{ color: "black", alignSelf: "flex-start" }} >{name}</Typography>
            <Chip onClick={() => navigate(0)} sx={getChipColors(points)} size="small" label={`pontos: ${points !== null && points !== undefined && points.toFixed(2) || ""}`} />
          </Stack>
        </Stack>

      </Item>
    </Container>
  )
}
