import axios from "axios"
import Cookies from "universal-cookie"
import { ACCESS_TOKEN_LIFETIME, API_BASE_URL, REFRESH_TOKEN_LIFETIME } from "../settings"


const cookies = new Cookies()

const getClient = () => {

  const client = axios.create({
    baseURL: API_BASE_URL
  })

  client.interceptors.request.use(
    (config) => {
      const accessToken = cookies.get("accessToken")
      if (accessToken && config.headers) {
        config.headers["Authorization"] = accessToken
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (error.response && (error.response?.status === 403 || error.response?.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true

        const accessToken = await refreshTokens()
        client.defaults.headers.common["Authorization"] = accessToken
        return client(originalRequest)
      }

      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        cookies.remove("accessToken", { path: "/" })
        cookies.remove("refreshToken", { path: "/" })
      }

      return Promise.reject(error)
    }
  )
  return client
}

const saveTokensToCookies = (accessToken, refreshToken) => {
  const accessTokenExpires = new Date(new Date().getTime() + ACCESS_TOKEN_LIFETIME * 1000)
  const refreshTokenExpires = new Date(new Date().getTime() + REFRESH_TOKEN_LIFETIME * 1000)

  cookies.set("accessToken", accessToken, { path: "/", expires: accessTokenExpires })
  cookies.set("refreshToken", refreshToken, { path: "/", expires: refreshTokenExpires })
}

const refreshTokens = async () => {
  const refreshToken = cookies.get("refreshToken")
  if (!refreshToken) {
    throw new Error("No refresh token available")
  }

  try {
    const response = await getClient().post("/users/refresh", { refreshToken: refreshToken })
    const { accessToken, refreshToken: newRefreshToken } = response.data

    saveTokensToCookies(accessToken, newRefreshToken)

    return accessToken
  } catch (error) {
    if (error.response && error.response.status === 400) {
      cookies.remove("accessToken", { path: "" })
      cookies.remove("refreshToken", { path: "" })
    }
    throw new Error(error)
  }
}

export const validateTokens = async () => {
  const refreshToken = cookies.get("refreshToken")
  const accessToken = cookies.get("accessToken")

  if (!refreshToken) {
    throw new Error("user is not authenticated")
  }

  if (!accessToken) {
    return refreshTokens()
  }
}

export const login = async (username, password) => {
  try {
    const response = await getClient().post("/users/login", { username, password })
    const { accessToken, refreshToken } = response.data
    saveTokensToCookies(accessToken, refreshToken)
    return response.data
  } catch (error) {
    cookies.remove("accessToken", { path: "" })
    cookies.remove("refreshToken", { path: "" })
    throw error
  }
}

export const getTodos = async () => {
  return await getClient().get("/todos")
}

export const createTodo = async (text) => {
  return await getClient().post("/todos", { text })
}

export const updateTodo = async (todoID, text, completed) => {
  return await getClient().put(`/todos/${todoID}`, { text, completed })
}

export const deleteTodo = async (todoID) => {
  return await getClient().delete(`/todos/${todoID}`)
}

export const getMe = async () => {
  return await getClient().get("/users/me")
}

export const getUser = async (userID) => {
  return await getClient().get(`/users/${userID}`)
}

export const updateUser = async (data) => {
  return await getClient().put(`/users/${data.id}`, data)
}

export const getPointLists = async (userID) => {
  return await getClient().get(`/users/${userID}/point-lists`)
}

export const getPointsEvaluated = async (userID) => {
  return await getClient().get(`/users/${userID}/point-lists/evaluated`)
}

export const createPoint = async (userID, pointListID, description, value, date) => {
  return await getClient().post(
    `/users/${userID}/point-lists/${pointListID}/points`,
    { description, value, date },
  )
}

export const editPoint = async (userID, pointListID, pointID, description, value, date) => {
  return await getClient().put(
    `/users/${userID}/point-lists/${pointListID}/points/${pointID}`,
    { description, value, date },
  )
}

export const deletePoint = async (userID, pointListID, pointID) => {
  return await getClient().delete(
    `/users/${userID}/point-lists/${pointListID}/points/${pointID}`,
  )
}
