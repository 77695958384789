import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Step from "@mui/material/Step"
import StepContent from "@mui/material/StepContent"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import Typography from "@mui/material/Typography"


export default function VerticalLinearStepper(props) {
  const {
    activeStep,
    setActiveStep,
    steps,
  } = props

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => setActiveStep(0)

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Última etapa</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent sx={{
              minWidth: {
                md: "260px"
              }
            }}>
              <Typography sx={{ color: "grey" }}>{step.description}</Typography>
              {step.content && step.content}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={step.disabled}
                  >
                    {index === steps.length - 1 ? "Finalizar" : "Continuar"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Voltar
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {
        activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            {/* <Typography sx={{ color: "black" }}>Todos passos estão completos</Typography> */}
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Começar de novo
            </Button>
          </Paper>
        )
      }
    </Box >
  )
}
