import {
  Box, List,
  ListItem,
  ListItemText,
  TextField
} from "@mui/material"
import { useState } from "react"

import DeleteIcon from "@mui/icons-material/Delete"
import Backdrop from "@mui/material/Backdrop"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import { enqueueSnackbar } from "notistack"
import { useLoaderData } from "react-router-dom"
import * as Api from "../services/api-service"
import Jumbotron from "./Jumbotron"
import Container from "@mui/material/Container"

import InputAdornment from "@mui/material/InputAdornment"
import Iconify from "../components/iconify"


const TodoList = () => {
  const response = useLoaderData()
  const [tasks, setTasks] = useState(response.data || [])
  const [taskText, setTaskText] = useState("")
  const [loading, setLoading] = useState(false)

  if (response.error) {
    return <>
      <section>
        <Jumbotron title={"oops!"} description={"ocorreu um erro ao carregar a lista: " + response.error} />
      </section>
    </>
  }

  const addTask = () => {
    const text = taskText.trim()
    if (text === "") {
      return
    }

    if (text.length > 40) {
      enqueueSnackbar("a tarefa deve ter no máximo 40 caracteres", {
        variant: "warning",
        autoHideDuration: 4000,
      })
      return
    }

    if (text.length < 3) {
      enqueueSnackbar("a tarefa deve no mínimo 3 caracteres", {
        variant: "warning",
        autoHideDuration: 4000,
      })
      return
    }

    if (tasks.some((task) => !task.completed && task.text.toLowerCase() === text.toLowerCase())) {
      enqueueSnackbar("a tarefa já existe", {
        variant: "warning",
        autoHideDuration: 4000,
      })
      return
    }
    setLoading(true)

    Api.createTodo(text)
      .then((response) => {
        setTasks([response.data, ...tasks])
        setTaskText("")
      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível adicionar a tarefa"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
      .finally(() => setLoading(false))
  }

  const updateTask = (task) => {
    setLoading(true)
    Api.updateTodo(task.id, task.text, !task.completed)
      .then(() => {
        setTasks(tasks.map((t) => {
          if (t.id === task.id) {
            t.completed = !t.completed
          }
          return t
        }))
      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível atualizar a tarefa"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
      .finally(() => setLoading(false))
  }

  const removeTask = (task) => {
    setLoading(true)
    Api.deleteTodo(task.id)
      .then(() => {
        setTasks(tasks.filter((t) => t.id !== task.id))
      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível remover a tarefa"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
      .finally(() => setLoading(false))
  }

  const listItemTextStyles = (completed) => ({
    textAlign: "center",
    textDecoration: completed ? "line-through" : "inherit",
    color: completed ? "grey" : "inherit"
  })

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ px: "0" }} maxWidth="lg">
        <Box p={2}>
          <TextField
            fullWidth
            label="adicionar atividade"
            variant="outlined"
            value={taskText}
            onBlur={addTask}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === "Done") {
                addTask()
              }
            }}
            InputProps={{
              style: { textAlign: "center" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={addTask}>
                    <Iconify icon={"lets-icons:add-square-duotone"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { textAlign: "center" } }}
            onChange={(e) => setTaskText(e.target.value)}
          />
        </Box>

        <List>
          {tasks.map((task) => (
            <ListItem key={task.id}>
              <Checkbox
                onClick={() => updateTask(task)}
                checked={task.completed}
                color="primary">
                <DeleteIcon />
              </Checkbox>
              <ListItemText
                primary={task.text}
                style={listItemTextStyles(task.completed)}
              />
              <IconButton
                color="primary"
                onClick={() => removeTask(task)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  )
}

export default TodoList
