import { createContext, useContext, useEffect, useState } from "react"
import { decodeAccessToken } from "../functions"

const AccessTokenContext = createContext()

export const AccessTokenProvider = ({ children }) => {
  const [decodedToken, setDecodedToken] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const decoded = await decodeAccessToken()
        setDecodedToken(decoded)
      } catch (error) {
        console.error("could not decode access token", error)
      }
    }

    fetchData()
  }, [])

  return (
    <AccessTokenContext.Provider value={decodedToken}>
      {children}
    </AccessTokenContext.Provider>
  )
}

export const useAccessToken = () => useContext(AccessTokenContext)
