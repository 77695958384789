import { Box, Container, Stack, Unstable_Grid2 as Grid } from "@mui/material"
import Navbar from "../components/Navbar"
import AccountProfile from "../components/Profile"
import ProfileDetails from "../components/ProfileDetails"
import { useLoaderData } from "react-router-dom"
import Jumbotron from "../components/Jumbotron"


export default function Profile() {

  const response = useLoaderData()
  const user = response.data

  if (response.error) {
    return <>
      <section>
        <Jumbotron title={"oops!"} description={response.error} />
      </section>
    </>
  }

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <AccountProfile user={user} />
                </Grid>
                <Grid
                  xs={12}
                  md={8}
                  lg={8}
                >
                  <ProfileDetails user={user} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
