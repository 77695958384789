import { redirect } from "react-router-dom"
import * as Api from "../services/api-service"


async function loginAction({ request }) {
  const formData = await request.formData()
  const { username, password } = Object.fromEntries(formData)

  try {
    const response = await Api.login(username, password)
    localStorage.setItem("profilePicture", response.profilePicture)
  } catch (error) {
    if (error.response?.status === 400) {
      return {
        error: "credenciais inválidas"
      }
    } else {
      return {
        error: "não foi possível autenticar, tente mais tarde: " + error
      }
    }
  }
  const search = new URLSearchParams(new URL(request.url).search)
  return redirect(search.get("from") || "/")
}

export default loginAction
