import { useRouteError } from "react-router-dom"


function ErrorPage() {
  const error = useRouteError()

  return (
    <section>
      <h1>oops!</h1>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </section>
  )
}


export default ErrorPage
