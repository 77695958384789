import { useLoaderData } from "react-router-dom"
import Navbar from "../components/Navbar"
import PointList from "../components/PointList"
import UserInfo from "../components/UserInfo"
import { calculatePoints } from "../functions"

function Points() {

  const data = useLoaderData()
  // TODO: hardcoded for now
  let props = {}
  const result = data?.data

  if (result) {
    const pl = data?.data[0]

    if (pl && pl.evaluatedUser) {
      const points = calculatePoints(pl)
      props = { ...pl.evaluatedUser, points }
    }
  }

  return (
    <>
      <Navbar />
      <UserInfo {...props} />
      <PointList userProps={props} />
    </>
  )
}

export default Points
