import Navbar from "../components/Navbar"
import TodoList from "../components/TodoList"

function Todo() {
  return (
    <>
      <Navbar />
      <TodoList />
    </>
  )
}


export default Todo
