import { jwtDecode } from "jwt-decode"
import Cookies from "universal-cookie"


const cookies = new Cookies()


export const decodeAccessToken = () => {
  const accessToken = cookies.get("accessToken")
  if (accessToken) {
    return jwtDecode(accessToken)
  }
  return null
}

export const getInitials = (name = "") => name
  .replace(/\s+/, " ")
  .split(" ")
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join("")


export const calculatePoints = (pointList) => pointList?.points?.reduce((accumulator, currentValue) => {
  return accumulator + currentValue.value
}, 0)
