import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from "@mui/material"
import { useCallback, useState } from "react"
import {
  Form, useActionData, useNavigation
} from "react-router-dom"

import { CircularProgress } from "@mui/material"
import { enqueueSnackbar } from "notistack"

import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Iconify from "../components/iconify"


export default function ProfileDetails({ user }) {
  const [values, setValues] = useState({
    name: user.name,
    email: user.email,
  })
  const [passLocked, setPassLocked] = useState(true)
  const data = useActionData()

  // TODO: fix multiple times rendering
  const success = data && data.success
  if (success) {
    enqueueSnackbar("dados salvos com sucesso", {
      variant: "success",
      autoHideDuration: 4000,
    })
  }

  const navigation = useNavigation()
  const errors = useActionData()

  errors && errors.error && enqueueSnackbar(errors.error, {
    variant: "error",
    autoHideDuration: 4000,
  })

  const handleChange = useCallback(
    (event) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))
    },
    []
  )
  return (
    <Form
      autoComplete="on"
      method="post"
    >
      <input name="id" style={{ "display": "none" }} readOnly={true} type="text" value={user.id} />
      <Card>
        <CardHeader
          subheader="esses dados podem ser editados"
          title="Perfil"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText="Digite seu nome"
                  label="Nome"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText="Digite seu email"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText={!passLocked ? "Digite sua senha" : "Desbloqueie para alterar sua senha"}
                  label="Senha"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  disabled={passLocked}
                  InputProps={{
                    style: { textAlign: "center" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setPassLocked(!passLocked)}>
                          <Iconify icon={passLocked ? "mdi:lock" : "mdi:lock-open"} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained" type="submit">
            {navigation.state === "idle" ? "salvar" : <CircularProgress color="inherit" size={25} />}
          </Button>
        </CardActions>
      </Card>
    </Form>
  )

}
