import { redirect } from "react-router-dom"
import { validateTokens } from "../services/api-service"
import * as Api from "../services/api-service"

import Cookies from "universal-cookie"
import { decodeAccessToken } from "../functions"


async function protectedLoader({ request }) {
  try {
    await validateTokens()
  } catch (error) {
    const cookies = new Cookies()
    cookies.remove("accessToken", { path: "/" })
    cookies.remove("refreshToken", { path: "/" })
    const params = new URLSearchParams()
    params.set("from", new URL(request.url).pathname)
    return redirect("/login?" + params.toString())
  }
  return null
}

async function logoutLoader() {
  const cookies = new Cookies()
  cookies.remove("accessToken", { path: "/" })
  cookies.remove("refreshToken", { path: "/" })
  return redirect("/")
}

async function todoListLoader({ request }) {
  const p = await protectedLoader({ request: request })
  if (p) {
    return p
  }

  try {
    return await Api.getTodos()
  } catch (error) {
    return {
      error: "não foi possível carregar a lista de tarefas"
    }
  }
}

async function meLoader({ request }) {
  const p = await protectedLoader({ request: request })
  if (p) {
    return p
  }

  try {
    const response = await Api.getMe()
    localStorage.setItem("profilePicture", response.data.profilePicture)
    return response
  } catch (error) {
    return {
      error: "ocorreu um erro ao carregar seu perfil: " + error
    }
  }
}

async function pointsLoader({ request }) {
  const p = await protectedLoader({ request: request })
  if (p) {
    return p
  }

  try {
    const userData = decodeAccessToken()
    const pointLists = await Api.getPointLists(userData.id)

    const promises = pointLists.data.map(async (pointList) => {
      const evaluatedUser = await Api.getUser(pointList.evaluatedUserID)
      pointList.evaluatedUser = evaluatedUser.data
      return pointList
    })

    const results = await Promise.all(promises)
    return { data: results }

  } catch (error) {
    return {
      error: "não foi possível carregar o extrato: " + error
    }
  }
}

async function pointsEvaluatedLoader({ request }) {
  const p = await protectedLoader({ request: request })
  if (p) {
    return p
  }

  try {
    const userData = decodeAccessToken()
    return await Api.getPointsEvaluated(userData.id)
  } catch (error) {
    return {
      error: "não foi possível carregar o extrato: " + error
    }
  }
}

export {
  protectedLoader,
  logoutLoader,
  todoListLoader,
  meLoader,
  pointsLoader,
  pointsEvaluatedLoader,
}
