import Navbar from "../components/Navbar"


function Invite() {

  return (
    <>
      <Navbar />
      <p style={{ display: "none" }}></p >
      <section>
        <h1>oii, ana</h1>
        <p>quero começar dizendo que eu fiz esse site para dizer que foi legal te {"encontrar"}</p>
        <small>mesmo tendo sido pouco tempo ainda</small>
      </section>
      {/* <TodoList /> */}

      <section>
        <p>e dizer que você me chamou atenção por vários motivos</p>
      </section>

      <section>
        <p>você é a pessoa que arrasa nas fotos internacionais e precisa me indicar lugares bons assim</p>
        <small>já falei que vai ser minha consultora de viagens hahaha</small>
      </section>

      <section>
        <p>e não poderia deixar de citar que é linda :)</p>
      </section>

      <section>
        <p>aquela pessoa que é bem enjoada para comidas</p>
        <small>pelo menos ama massas que é um dos meus pratos preferidos haha</small>
      </section>

      <section>
        <p>que tinha um cabelão enorme</p>
      </section>

      <img className="img" src="/img/ana-cabelo-longo.jpeg" alt="" />

      <section>
        <p>mas que super combina com cabelo curto</p>
        <small>amei</small>
      </section>

      <img className="img" src="/img/ana-cabelo-curto.jpeg" alt="" />

      <section>
        <p>só que se arrepende depois de cortar haha</p>
      </section>

      <section>
        <p>também era loira na infância</p>
        <small>com franjinha</small>
      </section>

      <img className="img" src="/img/ana-franjinha.jpeg" alt="" />

      <section>
        <p>e hoje é uma princesa</p>
      </section>

      <img className="img" src="/img/ana-princesa.jpeg" alt="" />

      <section>
        <p>ela é engenheira, deve gostar de cáculos</p>
      </section>

      <section>
        <p>mas também é empreendedora</p>
        <small>então é corajosa e deve topar vários desafios</small>
      </section>

      <section>
        <p>por todas essas razões eu quero te fazer um convite</p>
      </section>

      <section>
        <p>convidá-la para sair para jantar comigo esse final de semana</p>
      </section>

      <section>
        <p>seria uma oportunidade para conversarmos pessoalmente, nos conhecermos melhor e nos divertirmos também</p>
      </section>

      <section>
        <h2>você aceita?</h2>
      </section>

      <section>
        <p>eu tenho certeza que esse tempo que vamos passar juntos vai ser bem legal</p>
      </section>

      <section>
        <p>então saiba que estou na expectativa da sua resposta</p>
      </section>

      <section>
        <p>e para finalizar com essa foto linda, com esse vestido maravilhoso na sua cor favorita</p>
      </section>

      <img className="img" src="/img/ana-verona.jpeg" alt="" />

      <section>
        <small>para que você tenha muita sorte no amor {"<3"} </small>
      </section>
    </>
  )
}

export default Invite
