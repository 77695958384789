import { useLoaderData } from "react-router-dom"
import Navbar from "../components/Navbar"
import Scorecard from "../components/Scorecard"
import UserInfo from "../components/UserInfo"
import { useAccessToken } from "../helpers/contexts"
import { calculatePoints, decodeAccessToken } from "../functions"

function Score() {
  let userData = useAccessToken()
  if (userData) {
    userData.profilePicture = localStorage.getItem("profilePicture")
  } else {
    const fetchData = async () => {
      try {
        const decoded = decodeAccessToken()
        userData = decoded
        userData.profilePicture = localStorage.getItem("profilePicture")
      } catch (error) {
        console.error("could not decode access token", error)
      }
    }
    fetchData()
  }

  const response = useLoaderData()
  const pointLists = response.data || []
  // TODO: hardcoded for now

  let points = 0
  const pointList = pointLists[0]
  if (pointList && pointList.points) {
    points = calculatePoints(pointList)
    if (userData) {
      userData.points = points
    }
  }

  return (
    <>
      <Navbar />
      <UserInfo {...userData} />
      <Scorecard />
    </>
  )
}


export default Score
