import Button from "@mui/material/Button"
import { Link } from "react-router-dom"


export default function Jumbotron({ title, description }) {
  return (
    <>
      <section style={{ height: "100vh", width: "100%" }}>
        <h1>{title}</h1>
        <p>
          <i>{description}</i>
        </p>
        <Link to={"/"}>
          <Button variant="contained" sx={{ mt: "100px" }}>retornar</Button>
        </Link>
      </section>
    </>
  )
}
