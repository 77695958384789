import { useState } from "react"

import {
  Form, useActionData, useNavigation,
} from "react-router-dom"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Iconify from "../components/iconify"
import { enqueueSnackbar } from "notistack"
import { CircularProgress } from "@mui/material"


export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const navigation = useNavigation()
  const errors = useActionData()


  errors && errors.error && enqueueSnackbar(errors.error, {
    variant: "error",
    autoHideDuration: 4000,
  })
  const renderForm = (
    <>
      <Form method="post">
        <Stack spacing={3}>
          <TextField name="username" label="usuário" />
          <TextField
            name="password"
            label="senha"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Button
          sx={{ my: 3 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {navigation.state === "idle" ? "entrar" : <CircularProgress color="inherit" size={25} />}
        </Button>

      </Form>
    </>
  )

  return (
    <Box>
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1, padding: "40px" }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h3">login</Typography>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              acesso ao nosso espaço
            </Typography>
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  )
}
