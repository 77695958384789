import PlusIcon from "@mui/icons-material/Add"
import { CircularProgress, SvgIcon } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import dayjs from "dayjs"
import { Fragment, useEffect, useState } from "react"
import { useLoaderData } from "react-router-dom"
import VerticalLinearStepper from "./Stepper"

import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"

import { enqueueSnackbar } from "notistack"
import * as Api from "../services/api-service"


const NumberInput = ({ value, setValue }) => {

  const handleInputChange = (event) => {
    const inputValue = event.target.value

    const regex = /^-?\d*\.?\d{0,2}$/
    if (regex.test(inputValue) || inputValue === "") {
      setValue(inputValue)
    }
  }

  return (
    <TextField
      helperText="informe a quantidade de pontos"
      value={value}
      onChange={handleInputChange}
      type="text"
      InputProps={{
        inputProps: {
          pattern: "^\\d*\\.?\\d{0,2}$",
        },
      }}
    />
  )
}

function BasicDatePicker({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label="Selecione a data"
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}


export default function ResponsiveDialog({ pointLists, setPointLists, open, setOpen, selectedPoint, edit, setEdit, setSelectedPoint, setAnchorEl, editable }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [loading, setLoading] = useState(false)


  const [activeStep, setActiveStep] = useState(0)
  const [date, setDate] = useState(dayjs())
  const [description, setDescription] = useState("")
  const [point, setPoint] = useState("")

  const loaderData = useLoaderData()

  // TODO: hardcoded for now
  const data = loaderData && loaderData.data[0]
  const { userID, id } = data

  const steps = [
    {
      label: "Selecione a data da ocorrência",
      description: "",
      content: <BasicDatePicker value={date} setValue={setDate} />
    },
    {
      label: "Descrição",
      description: "",
      disabled: description.trim().length < 8,
      content: <TextField
        id="outlined-multiline-static"
        label="Descreva o ocorrido"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={2}
      />
    },
    {
      label: "Pontos",
      description: "",
      disabled: point === "" || point < -50 || point > 50,
      content: <NumberInput value={point} setValue={setPoint} />
    }
  ]

  const props = {
    activeStep,
    setActiveStep,
    date,
    setDate,
    description,
    setDescription,
    point,
    setPoint,
    steps,
  }

  useEffect(() => {
    if (edit) {
      handleClickOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])


  const handleClickOpen = () => {
    if (selectedPoint !== null) {
      setDate(dayjs(selectedPoint.date))
      setDescription(selectedPoint.description)
      setPoint(selectedPoint.value)
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setSelectedPoint(null)
    setAnchorEl(null)
  }

  const handleUpdate = () => {
    setLoading(true)
    Api.editPoint(userID, id, selectedPoint.id, description, parseFloat(point), date)
      .then(() => {
        setOpen(false)

        const index = pointLists[0].points.findIndex((p) => p.id === selectedPoint.id)
        pointLists[0].points[index] = {
          ...selectedPoint,
          description,
          value: parseFloat(point),
          date: date.toISOString(),
        }

        enqueueSnackbar("ponto atualizado com sucesso", {
          variant: "success",
          autoHideDuration: 4000,
        })

      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível atualizar o ponto"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
      .finally(() => {
        setSelectedPoint(null)
        setAnchorEl(null)
      })
  }


  const handleSave = () => {
    if (selectedPoint === null) {
      return handleCreate()
    }
    return handleUpdate()
  }

  const handleCreate = () => {
    setLoading(true)
    Api.createPoint(userID, id, description, parseFloat(point), date)
      .then((res) => {
        setOpen(false)

        const pointAdded = res.data

        enqueueSnackbar("ponto adicionado com sucesso", {
          variant: "success",
          autoHideDuration: 4000,
        })

        // TODO: hardcoded for now
        const pl = pointLists[0]
        pl.points.push(pointAdded)
        setPointLists([pl])

        // clear values
        setDescription("")
        setPoint("")
        setDate(dayjs())
        // reset stepper
        setActiveStep(0)
      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível adicionar o ponto"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
      .finally(() => {
        setLoading(false)
        setSelectedPoint(null)
        setAnchorEl(null)
      })

  }

  return (
    <Fragment>
      {editable ? (
        <Button
          startIcon={(
            <SvgIcon fontSize="small">
              <PlusIcon />

            </SvgIcon>
          )}
          variant="contained"
          sx={{ my: "15px" }}
          onClick={handleClickOpen}
        >
          Adicionar ponto
        </Button>
      ) : ""}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Adicionar ponto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <VerticalLinearStepper {...props} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} autoFocus onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            autoFocus
            disabled={loading || activeStep < steps.length}>
            {loading ? <CircularProgress color="inherit" size={25} /> : "salvar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
