import { forwardRef } from "react"
import { Icon } from "@iconify/react"

import Box from "@mui/material/Box"


function Iconify({ icon, width = 20, sx, ...other }, ref) {
  return (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
}

export default forwardRef(Iconify)
