import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import React, { useState, useRef } from "react"
import AvatarEditor from "react-avatar-editor"
import Dropzone from "react-dropzone"

import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import * as Api from "../services/api-service"

import SaveIcon from "@mui/icons-material/Save"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import SpeedDialIcon from "@mui/material/SpeedDialIcon"
import { useNavigate } from "react-router-dom"

import { enqueueSnackbar } from "notistack"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"


function ControlledOpenSpeedDial({ actions }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  return (
    <SpeedDial
      ariaLabel="SpeedDial controlled open example"
      sx={{ position: "absolute", right: 56 }}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      direction={"left"}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  )
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
}

export default function ImageUploader({ user }) {
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState(null)
  const [, setImageFile] = useState(null)
  const [scale, setScale] = useState(1)
  const [loading, setLoading] = useState(false)

  const editor = useRef(null)

  const navigate = useNavigate()

  const fileReader = new FileReader()
  fileReader.onload = function (event) {
    const base64String = event.target.result
    setLoading(true)
    Api.updateUser({ ...user, "profilePicture": base64String })
      .then(() => {
        navigate("/perfil")
        enqueueSnackbar("imagem salva com sucesso", {
          variant: "success",
          autoHideDuration: 4000,
        })
      })
      .catch((error) => enqueueSnackbar("não foi possível salvar a imagem, tente novamente mais tarde: " + error, {
        variant: "error",
        autoHideDuration: 4000,
      }))
      .finally(() => setLoading(false))
  }

  const save = () => {
    if (editor) {
      const canvasScaled = editor.current.getImageScaledToCanvas()
      canvasScaled.toBlob((blob) => {
        fileReader.readAsDataURL(blob)
        handleClose()
      }, "image/jpeg", 0.2)
    }
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setImage(URL.createObjectURL(file))
      setImageFile(file)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  const handleZoomIn = () => {
    setScale(scale + 0.1)
  }

  const handleZoomOut = () => {
    if (scale >= 0.2) {
      setScale(scale - 0.1)
    }
  }

  const actions = [
    { icon: <ZoomInIcon />, name: "zoom in", onClick: handleZoomIn },
    { icon: <ZoomOutIcon />, name: "zoom out", onClick: handleZoomOut },
    { icon: <SaveIcon />, name: "salvar", onClick: save },
  ]

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        fullWidth
        variant="text"
        onClick={handleOpen}
      >
        enviar imagem
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Dropzone
            onDrop={(dropped) => setImage(dropped[0])}
            noClick
            noKeyboard
            multiple={false}
            accept={{
              "image/jpeg": [],
              "image/png": []
            }}
            style={{ width: "250px", height: "250px" }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }} />

                <Button
                  htmlFor="fileInput"
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{ "marginY": "12px" }}>
                  escolher imagem
                </Button>

                {image && (
                  <AvatarEditor
                    ref={editor}
                    width={250}
                    height={250}
                    image={image}
                    color={[255, 255, 266, 0.1]}
                    border={50}
                    onLoadSuccess={() => setScale(1)}
                    scale={scale} />
                )}
                <input {...getInputProps()} />

                {image && (
                  <div>
                    <ControlledOpenSpeedDial actions={actions} />
                    {/*
                    <Button sx={{ "marginX": "5px" }} size="small" variant="contained" onClick={handleZoomIn}>
                      <ZoomInIcon />
                    </Button>
                    <Button sx={{ "marginX": "5px" }} size="small" variant="contained" onClick={handleZoomOut}>
                      <ZoomOutIcon />
                    </Button>
                    <Button sx={{ "marginX": "5px" }} size="small" variant="contained" onClick={handleZoomOut}>
                      <Save />
                    </Button> */}
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </Box>
      </Modal>
    </>
  )
}
