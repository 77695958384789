import {
  Box,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
} from "@mui/material"

import { parseISO } from "date-fns"
import { useState } from "react"
import { useLoaderData } from "react-router-dom"

import { useTheme } from "@mui/material"

import SimpleBar from "simplebar-react"
import Jumbotron from "./Jumbotron"
import ResponsiveDialog from "./ResponsiveDialog"
import VerticalLinearStepper from "./Stepper"

import {
  // TableContainer,
  // Paper,
  // IconButton,
  Popper,
  // ClickAwayListener,
  MenuItem,
  MenuList,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

import * as Api from "../services/api-service"

import { styled } from "@mui/material/styles"

import { enqueueSnackbar } from "notistack"

import { useAccessToken } from "../helpers/contexts"

const StyledSimpleBar = styled(SimpleBar)(() => ({
  "& .simplebar-placeholder": {
    "display": "none",
  },
}))

export const PointList = () => {

  const response = useLoaderData()
  const [pointLists, setPointLists] = useState(response.data || [])
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [edit, setEdit] = useState(false)

  // oper for the stepper
  const [open, setOpen] = useState(false)

  const popperOpen = Boolean(anchorEl)
  const theme = useTheme()

  const userData = useAccessToken()

  const props = {
    pointLists,
    setPointLists,
    open,
    setOpen,
    setSelectedPoint,
    selectedPoint,
    edit,
    setEdit,
    setAnchorEl,
    editable: true,
  }

  // TODO: hardcoded for now
  const pointList = pointLists[0]

  if (response.error || !pointList) {
    return <>
      <section>
        <Jumbotron title={"oops!"} description={"ocorreu um erro ao carregar a lista, tente novamente"} />
      </section>
    </>
  }

  const handleDelete = () => {
    const point = selectedPoint
    Api.deletePoint(userData.id, point.pointListID, point.id)
      .then(() => {
        enqueueSnackbar("ponto deletado com sucesso", {
          variant: "success",
          autoHideDuration: 4000,
        })

        // TODO: hardcoded for now
        const pl = pointLists[0]
        const index = pl.points.findIndex((p) => p.id === point.id)
        pl.points.splice(index, 1)
        setPointLists([pl])
        setAnchorEl(null)
        setSelectedPoint(null)
        setEdit(false)
      })
      .catch((error) => {
        const msg = error.response?.data?.error || "não foi possível deletar o ponto"
        enqueueSnackbar(msg, {
          variant: "error",
          autoHideDuration: 4000,
        })
      })
  }

  return (
    <>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          {/* <Stack spacing={1}>
            <Typography variant="h4">
              Extrato
            </Typography>
          </Stack> */}
        </Stack>
        <Card>
          <StyledSimpleBar>
            {/* <Box sx={{ minWidth: 800 }}> */}
            <Box>
              <Table sx={{ maxWidth: "100%" }} >
                <TableHead>
                  <TableRow
                    style={{ height: "auto" }}
                  >
                    {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAll}
                    indeterminate={selectedSome}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onSelectAll?.()
                      } else {
                        onDeselectAll?.()
                      }
                    }}
                  />
                </TableCell> */}
                    {pointList?.points?.length ? (
                      <>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Descrição
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Valor
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          Data
                        </TableCell>
                      </>
                    ) : ""}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pointList?.points?.map((point) => {
                    // const isSelected = selected.includes(point.id)

                    const dateAdded = parseISO(point.date).toLocaleDateString("pt-BR")

                    return (
                      <TableRow
                        hover
                        key={point.id}
                        onClick={(event) => {
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setSelectedPoint(selectedPoint ? null : point)
                        }}
                      // selected={isSelected}
                      >
                        {/* <TableCell padding="checkbox">
                      <Checkbox
                      // checked={isSelected}
                      // onChange={(event) => {
                      //   if (event.target.checked) {
                      //     onSelectOne?.(point.id)
                      //   } else {
                      //     onDeselectOne?.(point.id)
                      //   }
                      // }}
                      />
                    </TableCell> */}
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {point.description}
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", color: point.value < 0 ? "red" : "green" }}>
                          {point.value}
                        </TableCell>
                        <TableCell style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {dateAdded}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </StyledSimpleBar>

          {/* <Popper open={popperOpen}>
            <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
              <MenuList>
                <MenuItem >
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem>
                  <DeleteIcon />
                  Delete
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popper> */}

          <div>
            <Popper open={popperOpen} anchorEl={anchorEl}>
              <Box sx={{ backgroundColor: "white", borderRadius: "4px", boxShadow: 4 }}>
                <MenuList>
                  <MenuItem
                    sx={{ color: theme.palette.primary.main }}
                    onClick={() => setEdit(true)}>
                    <EditIcon sx={{ mr: "5px" }} />
                    Editar
                  </MenuItem>
                  <MenuItem
                    sx={{ color: theme.palette.primary.main }}
                    onClick={handleDelete}>
                    <DeleteIcon sx={{ mr: "5px" }} />
                    Deletar
                  </MenuItem>
                </MenuList>
              </Box>
            </Popper>
          </div>

          <div>
            <ResponsiveDialog {...props}>
              <VerticalLinearStepper />
            </ResponsiveDialog>
          </div>


          {/* <TablePagination
        component="div"
        count={pointList.points?.length || 0}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}
        // page={page}
        rowsPerPage={100}
        rowsPerPageOptions={[10, 50, 100]}
      /> */}
        </Card>


      </Container >
    </>
  )
}


export default PointList
