import React, { useState, useEffect, useCallback } from "react"

function Countdown({ targetDate }) {

  const [finished, setFinished] = useState(false)

  const calculateTimeRemaining = useCallback(() => {
    const currentDate = new Date().getTime()
    const timeRemaining = targetDate - currentDate


    if (timeRemaining <= 0) {
      setFinished(true)
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)
    const milliseconds = timeRemaining % 1000

    return {
      days,
      hours,
      minutes,
      seconds,
      milliseconds,
    }
  })

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining)

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const updatedTimeRemaining = calculateTimeRemaining()
      if (
        updatedTimeRemaining.days === 0 &&
        updatedTimeRemaining.hours === 0 &&
        updatedTimeRemaining.minutes === 0 &&
        updatedTimeRemaining.seconds === 0 &&
        updatedTimeRemaining.milliseconds === 0
      ) {
        clearInterval(countdownInterval)
      }
      setTimeRemaining(updatedTimeRemaining)
    }, 1)

    return () => {
      clearInterval(countdownInterval)
    }

  }, [calculateTimeRemaining, targetDate])

  return (
    !finished && (
      <div>
        <div>{timeRemaining.days} dia{timeRemaining.days !== 1 ? "s" : ""} </div>
        <div>{timeRemaining.hours} hora{timeRemaining.hours !== 1 ? "s" : ""} </div>
        <div>{timeRemaining.minutes} minuto{timeRemaining.minutes !== 1 ? "s" : ""} </div>
        <div>{timeRemaining.seconds} segundo{timeRemaining.seconds !== 1 ? "s" : ""} </div>
        {/* <div>milisegundos: {timeRemaining.milliseconds}</div> */}
      </div>
    )
  )
}

export default Countdown
