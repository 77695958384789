const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/"

const ACCESS_TOKEN_LIFETIME = 60 * 10
const REFRESH_TOKEN_LIFETIME = 60 * 60 * 24

export {
  API_BASE_URL,
  ACCESS_TOKEN_LIFETIME,
  REFRESH_TOKEN_LIFETIME,
}
