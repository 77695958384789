
import * as Api from "../services/api-service"

export async function userUpdateAction({ request }) {
  const formData = await request.formData()

  const userData = Object.fromEntries(formData)
  try {
    await Api.updateUser(userData)
    localStorage.setItem("profilePicture", userData.profilePicture)
    return {
      success: true,
    }
  } catch (error) {
    return {
      error: "ocorreu um erro ao carregar seu perfil: " + error
    }
  }
}
